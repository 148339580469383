import { ErrorPage } from '@/components';
import { ErrorPageCodes } from '@/constants';
import Head from 'next/head';

const NotFoundPage = (): React.ReactNode => {
  return (
    <>
      <Head>
        <title>Page Not Found | Parcel Monitor</title>
        <meta name='robots' content='noindex' />
        <meta name='googlebot' content='noindex' />
      </Head>
      <ErrorPage code={ErrorPageCodes.ERROR_404} />
    </>
  );
};

export default NotFoundPage;
